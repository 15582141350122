<template>
	<div class="AuditList">
		<w-navTab titleText="审核义警"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="AuditList-content">
			<div class="header-nav">
				<div :class="['header-nav-item', {'item-active': navIndex == item.id}]" v-for="(item,index) in navList" @click="onClickNav(index)">
					{{item.title}}
				</div>
			</div>
			<VantList class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="content-list" v-for="(item,index) in AuditList">
					<div class="header">
						<div class="header-left">
							<div class="header-left-icon">
								<img :src="item.avatar" alt="">
							</div>
							<div class="header-left-content">
								<div class="left-content-name">{{item.name}}</div>
								<div class="left-content-text">{{item.level_text}}</div>
							</div>
						</div>
						<div class="header-right"
							@click="$router.push({ name: 'AuditUserInfo', params:{id: item.user_id}})">
							个人信息<van-icon style="margin-left: 2px;" name="arrow" />
						</div>
					</div>
					<div class="body">
						<div class="body-content">
							<div class="body-content-lable">所属队伍：</div>
							<div class="body-content-text">{{item.department_txt}}</div>
						</div>
						<div class="body-content">
							<div class="body-content-lable">推荐人：</div>
							<div class="body-content-text">{{item.referrer}}</div>
						</div>
						<div class="body-content">
							<div class="body-content-lable">推荐人手机：</div>
							<div class="body-content-text">{{item.referrer_tel}}</div>
						</div>
					</div>
					<div class="footer">
						<div class="footer-text" v-if="navIndex == 1">申请日期：{{formatStartDate(item.create_time)}}</div>
						
						<template v-else>
							<div class="footer-text" v-if="item.review_time == ''">申请日期：{{formatStartDate(item.create_time)}}</div>
							<div class="footer-text" v-else>审核日期：{{formatStartDate(item.review_time)}}</div>
						</template>
						<div class="footer-botton" v-if="navIndex == 1" @click="$router.push({ name: 'AuditDetail', params:{id: item.id,type: navIndex}})">
							待审核</div>
						<div class="footer-right"v-if="navIndex == 2 && item.apply_status == 1" @click="$router.push({ name: 'AuditDetail', params:{id: item.id,type: navIndex}})">
							待上级审核
						</div>
					</div>
					<div class="content-status" v-if="navIndex == 2 && item.apply_status > 1" @click="$router.push({ name: 'AuditDetail', params:{id: item.id,type: navIndex}})">
						<img src="@/assets/img/u_icon121.png" alt="" v-if="item.apply_status == 2" >
						<img src="@/assets/img/u_icon122.png" alt="" v-else >
					</div>
				</div>
			</VantList>
		</div>
	</div>
</template>

<script>
	import User from '@/api/user';
	import VantList from '@/components/VantList';
	import VantVendor from '@/vendor/vant';
	import scrollMiXin from '@/mixins/scroll';
	import LoadingPage from '@/components/LoadingPage';
	import Event from '@/store/event';
	import Moment from '@/vendor/moment';
	
	export default {
		name: 'AuditList',
		data() {
			return {
				navIndex: 1,
				navList: [{id:1,title: '待审核'},{id: 2,title: '已审核'}],
				loadingPage: true,
				loading: true,
				finished: false,
				isEmpty: false,
				paramsPage: 1,
				paramsLimit: 10,
				AuditList: []
			}
		},
		created() {
			this.$store.commit('keep/setKeep', 'AuditList');
			this.onLoad()
			if(this.navIndex == 1){
				Event.$on('auditItem', itemId => {
					let index = this.AuditList.findIndex(item=>{return item.id == itemId})
					this.AuditList.splice(index,1)
					if(this.AuditList == ''){
						this.isEmpty = true
					}
				});
			}
		},
		methods: {
			formatStartDate(item) {
				return Moment(item).format('YYYY-MM-DD')
			},
			onLoad() {
				this.loading = true;

				let params = {
					page: this.paramsPage,
					limit: this.paramsLimit,
					type: this.navIndex
				};

				User.audiList(params)
					.then(result => {
						this.paramsPage++;
						this.AuditList = this.AuditList.concat(result.data.list);
						
						this.loading = false;
						
						this.loadingPage = false
						
						if (this.AuditList.length <= 0) {
							this.isEmpty = true;
						}

						if (result.data.list.length < this.paramsLimit) {
							this.finished = true;
						}
					})
					.catch(error => {
						// VantVendor.Toast.fail(error.msg);
					});
			},
			onClickNav(index){
				this.navIndex = this.navList[index].id
				this.paramsPage = 1
				this.AuditList = []
				this.finished = false
				this.isEmpty = false
				this.onLoad()
			}
		},
		components: {
			VantList,
			VantVendor,
			LoadingPage
		}
	}
</script>

<style scoped lang="less">
	.AuditList {
		.AuditList-content {

			.header-nav{
				padding-top: 11px;
				display: flex;
				justify-content: center;
				align-items: center;
				box-sizing: border-box;
				background-color: #fff;
				margin-bottom: 16px;
				
				.header-nav-item{
					padding-bottom: 8px;
					color: #999;
					text-align: center;
					font-size: 15px;
					border-bottom: 2px solid #fff;
					box-sizing: border-box;
					margin: 0 41px;
					line-height: 23px;
					
					&.item-active{
						color: #333;
						font-weight: bold;
						border-bottom: 2px solid #37F;
						box-sizing: border-box;
					}
				}
			}

			.content-list {
				border-radius: 8px;
				background: #FFF;
				padding: 0 12px;
				box-sizing: border-box;
				margin: 0 16px 12px;
				position: relative;
				&.content-list:last-child {
					margin-bottom: 0;
				}

				.header {
					padding: 15px 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 1px solid #F2F2F2;
					box-sizing: border-box;

					.header-left {
						display: flex;
						align-items: center;

						.header-left-icon {
							width: 40px;
							height: 40px;
							min-width: 40px;
							margin-right: 8px;

							img {
								width: 100%;
								height: 100%;
								border-radius: 50%;
								object-fit: cover;
							}
						}

						.header-left-content {
							.left-content-name {
								color: #333;
								font-size: 17px;
								line-height: 26px;
							}

							.left-content-text {
								color: #999;
								font-size: 13px;
								line-height: 20px;
							}
						}
					}

					.header-right {
						color: #999;
						font-size: 13px;
						line-height: 26px;
					}
				}

				.body {
					padding: 12px 0;
					border-bottom: 1px solid #F2F2F2;
					box-sizing: border-box;

					.body-content {
						display: flex;
						align-items: center;
						margin-top: 8px;

						&.body-content:first-child {
							margin-top: 0;
						}

						.body-content-lable {
							color: #999;
							font-size: 15px;
							line-height: 23px;
							flex-wrap: nowrap;
						}

						.body-content-text {
							flex: 1;
							color: #333;
							font-size: 15px;
							line-height: 23px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}

				.footer {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 10px 0;
					box-sizing: border-box;

					.footer-text {
						color: #999;
						font-size: 14px;
						line-height: 21px;
					}

					.footer-botton {
						width: 73px;
						padding: 4px 14px;
						color: #FFF;
						font-size: 15px;
						line-height: 23px;
						border-radius: 16px;
						background: #37F;
						box-sizing: border-box;
					}
					
					.footer-right{
						padding: 4px 14px;
						color: #fff;
						font-size: 15px;
						line-height: 23px;
						border-radius: 16px;
						background: #AAA;
						box-sizing: border-box;
					}
				}
			
				.content-status{
					position: absolute;
					bottom: 12px;
					right: 12px;
					width: 68px;
					height: 68px;
					
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}
</style>